<template>
  <section id="platform-settings-tokens">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("Tokens") }}</h1>
        <h5 class="text-primary">
          {{ $t("infoTokens") }}
        </h5>
      </b-col>
      <b-col class="element-end">
        <b-button
          variant="success"
          @click="$refs['superuser'].show()"
        >
          {{ $t('dataGeneric.create') }}
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col>
          <b-form-input
            v-model="search"
            :placeholder="$t('subscriptions.search')"
            @change="searching"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-table
            ref="tokensListTable"
            :items="allTokens"
            :fields="[
              { key: 'key', label: 'Token' },
              { key: 'user', label: $t('User') },
              { key: 'actions', label: $t('resources.tableHeader.actions') },
            ]"
            responsive="sm"
            :busy="show"
            show-empty
            :empty-text="$t('emptyText')"
            primary-key="key"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>{{ $t('load') }}...</strong>
              </div>
            </template>
            <!-- Column: key -->
            <template #cell(key)="data">
              <div class="text-nowrap">
                <span class="align-text-top">{{ data.item.node.key }}</span>
                <b-button
                  v-clipboard:copy="data.item.node.key"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  v-b-tooltip.hover.top="$t('dataGeneric.copy')+' token'"
                  variant="primary"
                  size="sm"
                  class="align-items-center ml-2"
                >
                  <feather-icon
                    icon="ShareIcon"
                  />
                  <!-- {{ $t('dataGeneric.copy') }} -->
                </b-button>
              </div>
            </template>

            <!-- Column: User -->
            <template #cell(user)="data">
              <div>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.node.user.username }}
                </span>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="text-nowrap d-flex pl-2">
                <feather-icon
                  v-b-tooltip.hover.top="$t('dataGeneric.delete')+' token'"

                  icon="Trash2Icon"
                  class="text-danger mr-2 cursor-pointer"
                  size="18"
                  @click="deleteTokens(data.item.node.id)"
                />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            @input="getTokens"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="superuser"
      ref="superuser"
      hide-footer
      size="lg"
      title="Seleccionar plataforma"
    >
      <div class="d-block text-center">
        <platform-selector @client="getPlatform" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['superuser'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
    <b-modal
      id="admin-user"
      ref="admin-user"
      hide-footer
      size="xl"
      title="Seleccionar usuario"
    >
      <div class="d-block text-center">
        <b-tabs
          pills
          lazy
        >
          <b-tab active>
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>{{ $t("code.admin") }}</span>
            </template>
            <admin-user-selector
              :platform-id="platformId"
              @selectUser="getAdmin"
              @back="getBack"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="UsersIcon" />
              <span>{{ $t("fileNameUserRegistered") }}</span>
            </template>
            <final-user-selector-platform
              :platform-id="platformId"
              @back="getBack"
              @finalUser="getAdmin"
            />
          </b-tab>
        </b-tabs>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['admin-user'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTabs,
  BTab,
  BSpinner,
  VBTooltip,
  BTable,
  BFormInput,
  BButton,
  BPagination,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import PlatformSelector from '@/views/common/PlatformSelector.vue'
import AdminUserSelector from '@/views/common/AdminUserSelector.vue'
import { getUserData } from '@/auth/utils'
import FinalUserSelectorPlatform from '../common/FinalUserSelectorPlatform.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BSpinner,
    PlatformSelector,
    BTabs,
    BTab,
    BPagination,
    BFormInput,
    FinalUserSelectorPlatform,
    AdminUserSelector,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      allTokens: [],
      currentPage: 1,
      show: false,
      rows: 0,
      perPage: 20,
      platformId: null,
      userId: null,
      search: '',
    }
  },
  mounted() {
    if (!getUserData().isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    this.getTokens()
  },
  methods: {
    getBack() {
      this.$refs.superuser.show()
      this.$refs['admin-user'].hide()
    },
    searching() {
      this.currentPage = 1
      this.getTokens()
    },
    getAdmin(data) {
      this.userId = data
      this.$refs['admin-user'].hide()
      this.generateTokens()
    },
    getPlatform(data) {
      this.platformId = data.id
      this.$refs.superuser.hide()
      this.$refs['admin-user'].show()
    },
    getTokens() {
      this.show = true
      const query = `
          {
            allTokens(
                first:${this.perPage},
                offset:${this.perPage * (this.currentPage - 1)},
                search_User:"${this.search}",
                orderBy:"-created"
                ) {
              totalCount
              edges {
                node {
                  id
                  key
                  user {
                    id
                    username
                  }
                }
              }
            }
          }
        `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        this.rows = res.data.data.allTokens.totalCount
        this.allTokens = res.data.data.allTokens.edges
        this.show = false
      })
    },
    generateTokens() {
      this.show = true
      const query = `
          mutation($user: ID!){
            generateTokens(input:{user:$user}){
              token{
                key
              }
            }
          }
        `
      axios.post('', {
        query,
        variables: {
          user: this.userId,
        },
      }).then(res => {
        messageError(res, this)
        showToast(this.$t('code.updateData'), 1, this)

        this.getTokens()
      }).catch(err => {
        console.log(err)
        showToast(this.$t('code.updateDataError'), 2, this)
        this.show = false
      })
    },
    deleteTokens(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteToken'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.show = true
          axios
            .post('', {
              query: `
                    mutation($id: ID!){
                      deleteTokens(id:$id){
                            found
                            deletedId
                        }
                    }
                    `,
              variables: {
                id,
              },
            })
            .then(res => {
              messageError(res, this)
              showToast(this.$t('code.updateData'), 1, this)
              this.getTokens()
            })
            .catch(err => {
              console.log(err)
              showToast(this.$t('code.updateDataError'), 2, this)
              this.show = false
            })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.tCopy'),
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.errorCopy'),
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
.element-end{
  text-align: end;
  align-self: center;

}
</style>
