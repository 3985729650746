<template>
  <section id="admin-users">
    <b-card>
      <b-card-header>
        <b-card-title>
          <h1>{{ $t("code.admin") }}</h1>
          <h5 class="text-primary">
            {{ $t("administrators.subtitle") }}
          </h5>
        </b-card-title>
        <div>
          <b-button
            variant="danger"
            size="sm"
            @click="back()"
          >
            {{
              $t("dataGeneric.goBack")
            }}
          </b-button>
          <b-button
            class="ml-1"
            variant="success"
            size="sm"

            :disabled="selected==null"
            @click="seleccionar"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </div>
      </b-card-header>
      <b-row>
        <b-col>
          <b-form-input
            v-model="search"
            :placeholder="$t('dataGeneric.search')"
            @change="searching"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-table
            ref="refUserListTable"
            :items="fetchUsers"
            :fields="[
              { key: 'username', label: $t('User') },
              { key: 'group', label: $t('Rol') },
              { key: 'email', sortable: true },
              { key: 'isActive', label: $t('dataGeneric.activeQ') },
            ]"
            hover
            :select-mode="'single'"
            responsive="sm"
            selectable
            show-empty
            :empty-text="$t('emptyText')"
            primary-key="id"
            :busy="show"
            @row-selected="onRowSelected"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>{{ $t('load') }}...</strong>
              </div>
            </template>
            <!-- Column: User -->
            <template #cell(username)="data">
              <b-media vertical-align="center">
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.node.firstName }} {{ data.item.node.lastName }}
                </span>

                <span class="font-weight-bold d-block text-nowrap">
                  <small class="text-muted">@{{ (data.item.node.username) }}</small>
                </span>
              </b-media>
            </template>

            <!-- Column: Email -->
            <template #cell(email)="data">
              <div
                v-if="data.item.node.email"
                class="text-nowrap"
              >
                <feather-icon
                  icon="MailIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="align-text-top">{{ data.item.node.email }}</span>
              </div>
            </template>
            <template #cell(group)="data">
              <div class="text-nowrap">
                <span class="align-text-top">{{
                  data.item.node.customuserPtr.groups.edges[0] != null
                    ? data.item.node.customuserPtr.groups.edges[0].node.name
                    : ""

                }}</span>
              </div>
            </template>
            <template #cell(isActive)="data">
              <b-badge
                pill
                :variant="`light-${data.item.node.isActive?'success':'secondary'}`"
                class="text-capitalize"
              >
                <feather-icon
                  :icon="data.item.node.isActive ? 'CheckIcon' : 'XIcon'"
                  size="18"
                  class="mr-50"
                />
                {{
                  data.item.node.isActive
                    ? $t("dataGeneric.active")
                    : $t("dataGeneric.inactive")
                }}
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            @input="getData"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>
<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BBadge,
  BFormInput,
  BCardHeader,
  BCardTitle,
  BPagination,
  BSpinner,
  BMedia,
  BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BTable,
    BRow,
    BFormInput,
    BBadge,
    BCol,
    BButton,
    BCard,
    BPagination,
    BMedia,
    BCardHeader,
    BCardTitle,
    BSpinner,
  },
  props: {
    platformId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userData: getUserData(),
      selected: null,
      fetchUsers: [],
      search: '',
      show: false,
      currentPage: 1,
      perPage: 14,
      rows: 0,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    back() {
      this.$emit('back')
    },
    onRowSelected(items) {
      if (items[0]) {
        this.selected = items[0].node.id
      } else {
        this.selected = null
      }
    },
    seleccionar() {
      this.$emit('selectUser', this.selected)
    },
    searching() {
      this.currentPage = 1
      this.getData()
    },
    getData() {
      this.show = true
      axios.post('', {
        query: `query($client: ID,$username: String){
          allProfiles (
            first:${this.perPage},
            offset:${this.perPage * (this.currentPage - 1)},  
            client:$client,username:$username
            ){
            totalCount
            edges {
              node {
                id
                username
                email
                firstName
                lastName
                isActive
                customuserPtr {
                  groups {
                    edges {
                      node {
                        id
                        name                        
                      }
                    }
                  }
                }  
              }
            }
          }
        }`,
        variables: {
          client: this.platformId,
          username: this.search,
        },
      }).then(res => {
        messageError(res, this)
        this.show = false
        this.rows = res.data.data.allProfiles.totalCount

        this.fetchUsers = res.data.data.allProfiles.edges
      }).catch(() => {
        this.show = false
      })
    },
  },

}
</script>
